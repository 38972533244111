import { MemoryRouter, Route } from "react-router-dom";
import { Routes } from "react-router";
import Login from "./Login";
import Register from "./Register";
import Reset from "./Reset";
import PasswordLost from "./PasswordLost";

export const CMS_PATH = "https://backend-loreal-hair.imascono.com";

function App() {
  return (
    <>
      <MemoryRouter>
        <Routes>
          <Route path="/reset" element={<Reset />} />
          <Route
            path="/"
            element={
              <>
                <Login />
                <Register />
                <PasswordLost />
              </>
            }
          />
        </Routes>
      </MemoryRouter>
    </>
  );
}

export default App;
