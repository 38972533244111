import Modal from "react-modal";
import { useEffect, useLayoutEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-regular-svg-icons";
import { faEyeSlash } from "@fortawesome/free-regular-svg-icons";
import useStore from "./store/store";
import { useSnackbar } from "notistack";
import { CMS_PATH } from "./App";

const Register = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const showRegisterModal = useStore((state) => state.showRegisterModal);
  const setShowRegisterModal = useStore((state) => state.setShowRegisterModal);

  const [firstName, setFirstName] = useState<string>("");
  const [lastName, setLastName] = useState<string>("");
  const [instagram, setInstagram] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [telephone, setTelephone] = useState<string>("");
  const [location, setLocation] = useState<string>("");
  const [privacity, setPrivacity] = useState<boolean>(false);
  const [emailNotification, setEmailNotification] = useState<boolean>(false);

  const [enableButton, setEnableButton] = useState<boolean>(false);

  useEffect(() => {
    setEnableButton(
      firstName.length > 0 &&
        lastName.length > 0 &&
        instagram.length > 0 &&
        email.length > 0 &&
        password.length > 0 &&
        checkPasswordValidity(password) &&
        telephone.length > 0 &&
        location.length > 0 &&
        privacity
    );
  }, [
    firstName,
    lastName,
    instagram,
    email,
    password,
    telephone,
    location,
    privacity,
  ]);

  function checkPasswordValidity(password: string) {
    // Regex to check if a string
    // contains uppercase, lowercase
    // special character & numeric value
    var pattern = new RegExp(
      "^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[-+_!@#$%^&*.,?]).+$"
    );

    // If the string is empty
    // then print No
    if (!password || password.length === 0 || password.length < 8) {
      return false;
    }

    // Print Yes If the string matches
    // with the Regex
    if (
      /(?=^.{8,}$)(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*()_+}{';'?>.<,])(?!.*\s).*$/gm.test(
        password
      )
    ) {
      return true;
    } else {
      return false;
    }
  }

  function register() {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    fetch(CMS_PATH + "/users", {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify({
        first_name: firstName,
        last_name: lastName,
        instagram: instagram,
        email: email,
        password: password,
        telephone: telephone,
        // role: "cab0f3eb-4d95-46ca-a09a-7a549c4d73c6", // Rol alumno
        location: location,
        email_notifications: emailNotification,
      }),
      redirect: "follow",
    })
      .then((response: Response) => {
        if (response.status === 400) {
          enqueueSnackbar("El registro de usuario ha fallado", {
            variant: "error",
          });
        } else if (response.status === 204) {
          enqueueSnackbar(
            "Registrado con éxito. Espera a que te den acceso para disfrutar de la experiencia",
            {
              variant: "success",
              autoHideDuration: 7000,
            }
          );
          setShowRegisterModal(false);
        }
        console.log(response);
      })
      .catch((error: any) => {
        console.log(error);
        enqueueSnackbar("El registro de usuario ha fallado", {
          variant: "error",
        });
      });
  }

  function checkFormValidity() {
    if (firstName.length <= 0) {
      enqueueSnackbar("Debe incluir un nombre válido", {
        variant: "error",
      });
    } else if (lastName.length <= 0) {
      enqueueSnackbar("Debe incluir un apellido válido", {
        variant: "error",
      });
    } else if (instagram.length <= 0) {
      enqueueSnackbar("Debe incluir un perfil de instagram válido", {
        variant: "error",
      });
    } else if (email.length <= 0) {
      enqueueSnackbar("Debe incluir un email válido", {
        variant: "error",
      });
    } else if (password.length <= 0) {
      enqueueSnackbar(
        "La contraseña debe contener al menos 8 carácteres, 1 dígito, 1 carácter especial [!@#$%^&*()_+}{';'?>.<,], una mayúscula y una minúscula",
        {
          variant: "error",
        }
      );
    } else if (!checkPasswordValidity(password)) {
      enqueueSnackbar(
        "La contraseña debe contener al menos 8 carácteres, 1 dígito, 1 carácter especial [!@#$%^&*()_+}{';'?>.<,], una mayúscula y una minúscula",
        {
          variant: "error",
        }
      );
    } else if (telephone.length <= 0) {
      enqueueSnackbar("Debe incluir un telefono válido", {
        variant: "error",
      });
    } else if (location.length <= 0) {
      enqueueSnackbar("Debe incluir una dirección postal válida", {
        variant: "error",
      });
    } else if (!privacity) {
      enqueueSnackbar(
        "Debe aceptar los términos de uso y la política de privacidad",
        {
          variant: "error",
        }
      );
    }
  }

  return (
    <Modal
      isOpen={showRegisterModal}
      onRequestClose={() => setShowRegisterModal(false)}
      contentLabel="Register Modal"
      ariaHideApp={false}
      style={{
        content: { transform: "translate(-50%, -50%)" },
      }}
      className="bg-transparent w-full h-full border-0 p-0 top-1/2 left-1/2 right-auto bottom-auto -mr-[50%] max-h-full absolute"
    >
      <div className="w-full h-full flex justify-center items-center bg-[#00000080]">
        <div
          id="divContentPassword"
          className="p-[3rem] bg-white w-full md:w-2/5 rounded-md max-h-full md:max-h-[80%] box-border overflow-y-auto relative"
        >
          <div className="absolute right-0 mr-4">
            <svg
              className="cursor-pointer fill-morado-loreal transform hover:scale-105"
              onClick={() => setShowRegisterModal(false)}
              width="48"
              height="48"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 512 512"
            >
              <path d="M0 256C0 114.6 114.6 0 256 0C397.4 0 512 114.6 512 256C512 397.4 397.4 512 256 512C114.6 512 0 397.4 0 256zM175 208.1L222.1 255.1L175 303C165.7 312.4 165.7 327.6 175 336.1C184.4 346.3 199.6 346.3 208.1 336.1L255.1 289.9L303 336.1C312.4 346.3 327.6 346.3 336.1 336.1C346.3 327.6 346.3 312.4 336.1 303L289.9 255.1L336.1 208.1C346.3 199.6 346.3 184.4 336.1 175C327.6 165.7 312.4 165.7 303 175L255.1 222.1L208.1 175C199.6 165.7 184.4 165.7 175 175C165.7 184.4 165.7 199.6 175 208.1V208.1z"></path>
            </svg>
          </div>

          <div className="flex justify-center">
            <img className="w-[7rem] mb-6" src="./images/logoBackground.svg" />
          </div>
          <h1 className="font-Staatliches text-center text-4xl leading-10 tracking-widest uppercase text-morado-loreal">
            REGÍSTRATE AHORA
          </h1>
          <p className="font-OpenSans text-base hidden text-morado-loreal">
            Sustituye la contraseña dada por una nueva para mejorar la seguridad
            de tu cuenta
          </p>
          <div className="label">
            <label htmlFor="email2">Email *</label>
            <div>
              <input
                onKeyUp={(event: any) => setEmail(event.target.value)}
                onInput={(event: any) => setEmail(event.target.value)}
                className="w-full font-OpenSans text-base text-[#00000080] px-2 h-11 rounded-lg bg-[#e7e7e7] box-border border-2 border-[#969696]"
                autoComplete="off"
                type="email"
                id="email2"
                name="email2"
                placeholder="Email"
                required
              />
            </div>
          </div>
          <div className="mt-4 label">
            <label htmlFor="password2">Password *</label>
            <div>
              <input
                onKeyUp={(event: any) => setPassword(event.target.value)}
                onInput={(event: any) => setPassword(event.target.value)}
                className="w-full font-OpenSans text-base text-[#00000080] px-2 h-11 rounded-lg bg-[#e7e7e7] box-border border-2 border-[#969696]"
                autoComplete="off"
                type={showPassword ? "text" : "password"}
                id="password2"
                name="password2"
                placeholder="Contraseña"
                required
              />
              <FontAwesomeIcon
                onClick={() => setShowPassword(!showPassword)}
                icon={showPassword ? faEye : faEyeSlash}
                className="inline cursor-pointer -ml-9"
              />
            </div>
            <small>
              La contraseña debe contener al menos 8 carácteres, 1 dígito, 1
              carácter especial {`[!@#$%^&*()_+}{';'?>.<,]`}, una mayúscula y
              una minúscula
            </small>
          </div>
          <div className="mt-4 label">
            <label htmlFor="name">Nombre *</label>
            <div>
              <input
                onKeyUp={(event: any) => setFirstName(event.target.value)}
                onInput={(event: any) => setFirstName(event.target.value)}
                className="w-full font-OpenSans text-base text-[#00000080] px-2 h-11 rounded-lg bg-[#e7e7e7] box-border border-2 border-[#969696]"
                autoComplete="off"
                type="name"
                id="name"
                name="name"
                placeholder="Nombre"
                required
              />
            </div>
          </div>
          <div className="mt-4 label">
            <label htmlFor="surname">Apellidos *</label>
            <div>
              <input
                onKeyUp={(event: any) => setLastName(event.target.value)}
                onInput={(event: any) => setLastName(event.target.value)}
                className="w-full font-OpenSans text-base text-[#00000080] px-2 h-11 rounded-lg bg-[#e7e7e7] box-border border-2 border-[#969696]"
                autoComplete="off"
                type="surname"
                id="surname"
                name="surname"
                placeholder="Apellidos"
                required
              />
            </div>
          </div>
          <div className="mt-4 label">
            <label htmlFor="instagram">Cuenta de Instagram/Tiktok *</label>
            <div>
              <input
                onKeyUp={(event: any) => setInstagram(event.target.value)}
                onInput={(event: any) => setInstagram(event.target.value)}
                className="w-full font-OpenSans text-base text-[#00000080] px-2 h-11 rounded-lg bg-[#e7e7e7] box-border border-2 border-[#969696]"
                autoComplete="off"
                type="instagram"
                id="instagram"
                name="instagram"
                placeholder="@tucuenta"
                required
              />
            </div>
          </div>
          <div className="mt-4 label">
            <label htmlFor="telephone">Teléfono móvil *</label>
            <div>
              <input
                onKeyUp={(event: any) => setTelephone(event.target.value)}
                onInput={(event: any) => setTelephone(event.target.value)}
                className="w-full font-OpenSans text-base text-[#00000080] px-2 h-11 rounded-lg bg-[#e7e7e7] box-border border-2 border-[#969696]"
                autoComplete="off"
                type="tel"
                id="telephone"
                name="telephone"
                placeholder="6XXXXXXXX"
                required
              />
            </div>
          </div>
          <div className="mt-4 label">
            <label htmlFor="location">Dirección postal *</label>
            <div>
              <input
                onKeyUp={(event: any) => setLocation(event.target.value)}
                onInput={(event: any) => setLocation(event.target.value)}
                className="w-full font-OpenSans text-base text-[#00000080] px-2 h-11 rounded-lg bg-[#e7e7e7] box-border border-2 border-[#969696]"
                autoComplete="off"
                type="location"
                id="location"
                name="location"
                placeholder="Calle, número, portal, localidad y código postal"
                required
              />
            </div>
          </div>
          <div className="flex items-center mt-4">
            <input
              onKeyUp={(event: any) => setPrivacity(event.target.checked)}
              onInput={(event: any) => setPrivacity(event.target.checked)}
              id="privacity"
              type="checkbox"
              value=""
              className="w-6 h-6"
            />
            <label htmlFor="privacity" className="ml-4  max-w-[85%]">
              He leído y acepto los{" "}
              <a
                href="./documents/terminos.pdf"
                target="_blank"
                className="cursor-pointer underline"
              >
                Términos de Uso
              </a>{" "}
              y he leido y entiendo la{" "}
              <a
                href="./documents/privacidad.pdf"
                target="_blank"
                className="cursor-pointer underline"
              >
                Política de Privacidad
              </a>{" "}
              de la web. *
            </label>
          </div>
          <div className="flex items-center mt-4">
            <input
              onKeyUp={(event: any) =>
                setEmailNotification(event.target.checked)
              }
              onInput={(event: any) =>
                setEmailNotification(event.target.checked)
              }
              id="newsletter"
              type="checkbox"
              value=""
              className="w-6 h-6"
            />
            <label htmlFor="newsletter" className="ml-4 max-w-[85%]">
              Acepto el envío de comunicaciones comerciales de L'Oréal España
              sobre sus productos y/o servicios por medios ordinarios y/o
              electrónicos.
            </label>
          </div>
          <p className="mt-4">* Obligatorio</p>
          <p className="text-xs">
            <u>Información básica sobre protección de datos</u> <br />
            <br />
            <b>Responsable</b>: L'Oréal España S.A.U.
            <br />
            <br />
            <b>Finalidad</b>: Las finalidades principales de tratamiento de sus
            datos personales son: la creación de su cuenta, el envío de
            comunicaciones comerciales y promocionales y elaboración de
            perfiles.
            <br />
            <br />
            <b>Derechos</b>: Acceder, rectificar y suprimir los datos, así como
            otros derechos, como se explica en la información adicional.
            <br />
            <br />
            <b>Información adicional</b>: Puede consultar la información
            adicional y detallada sobre Protección de Datos en nuestra{" "}
            <a href="./documents/privacidad.pdf">Política de Privacidad</a>.
          </p>

          <div id="divButtonRegister" className="flex justify-center mt-12">
            <button
              id="buttonRegister"
              onClick={() => {
                if (enableButton) {
                  register();
                } else {
                  checkFormValidity();
                }
              }}
              className={`w-full rounded-full h-16 bg-morado-loreal ${
                enableButton ? "" : "opacity-25"
              } text-white outline-none border-0 cursor-pointer text-2xl tracking-widest font-Staatliches`}
              style={{
                filter: "drop-shadow(0px 4px 8px rgba(47, 59, 87, 0.3))",
              }}
            >
              REGISTRARSE
            </button>
          </div>
          <h1 className="font-Staatliches text-center text-[#0066ff] mt-8">
            Desarrollado por Telefónica Educación Digital
          </h1>
        </div>
      </div>
    </Modal>
  );
};

export default Register;
