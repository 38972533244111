import create from "zustand";

interface IStore {
  showRegisterModal: boolean;
  setShowRegisterModal: (val: boolean) => void;
  showPasswordLostModal: boolean;
  setShowPasswordLostModal: (val: boolean) => void;
}

const useStore = create<IStore>((set, get) => ({
  showRegisterModal: false,
  setShowRegisterModal: (val: boolean) =>
    set(() => ({ showRegisterModal: val })),
  showPasswordLostModal: false,
  setShowPasswordLostModal: (val: boolean) =>
    set(() => ({ showPasswordLostModal: val })),
}));

export default useStore;
